export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: false,
  apiUrl: 'http://ecmsapi.centurycodes.ng/api',
  courtTitle: 'HIGH COURT OF DELTA',
  stateTitle: 'DELTA STATE OF NIGERIA',
  stateTitleSentence: 'Delta State Of Nigeria',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'http://ecmsapi.centurycodes.ng/api',
  BASE_URL_II: 'http://ecmsapi.centurycodes.ng/api',
  API_URL: '/',
  currentState: 'Delta State',
  court_bg_path: 'assets/img/court-bg-5.png',
  court_logo_path: 'assets/img/ecms-logos-delta.png',
  paystack_public_key: 'pk_test_24673c9637a1bf06e5fb6eb989012747183eb2ae',
  paystack_split_code: 'SPL_K0t6TE0Fmm'
};
